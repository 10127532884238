import styled from 'styled-components';

import { mq } from 'theme/media';

import { IconsProps } from './CoreTechnologies';

export const StyledCoreBackground = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing(9)};
  overflow: hidden;
  background: ${({ theme }) => theme.gradients.darkViolet};

  ${mq.large`
    padding: ${({ theme }) => theme.spacing(9, 0, 0)};
  `}

  ${mq.xsLarge`
    padding: ${({ theme }) => theme.spacing(22, 0, 0)};
  `}
`;

export const StyledColumns = styled.div`
  position: relative;
  display: grid;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  grid-template-areas:
    'leftIcons'
    'textColumn';

  ${mq.large`
    padding-bottom: ${({ theme }) => theme.spacing(24)};
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'leftIcons textColumn rightIcons';
  `}
`;

export const StyledTextWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const StyledTextColumn = styled.div`
  display: flex;
  grid-area: textColumn;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(8, 0)};

  ${mq.large`
    padding: 0;
  `}
`;

export const StyledLeftIconsColumn = styled.div`
  position: relative;
  grid-area: leftIcons;
  left: ${({ theme }) => theme.spacing(-6)};

  ${mq.large`
    left: 0;
  `}
`;

export const StyledRightIconsColumn = styled.div`
  grid-area: rightIcons;
  position: relative;
  display: inline;
`;

export const StyledIconsRow = styled.div<Partial<IconsProps>>`
  position: relative;
  padding-left: ${({ left }) => `calc(${left} * 2)` || 'auto'};
  right: ${({ right }) => right || 'auto'};
  min-width: max-content;

  ${mq.large`
    padding-left: 0;
    left: ${({ left }) => left || 'auto'};
  `}
`;
