import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';

import { AppLink } from 'components/AppLink/AppLink';

export const StyledContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  row-gap: ${({ theme }) => theme.spacing(1)};
  display: grid;
  align-self: flex-start;
  position: relative;
`;

export const StyledDate = styled.span`
  opacity: 0.75;
`;

export const StyledThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  overflow: hidden;
  padding-top: 56.25%;
`;

export const StyledLink = styled(AppLink)`
  ${mq.xsLarge`
    pointer-events: none;
    cursor: default;
  `}
`;

export const StyledTitleWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  background: linear-gradient(
    89deg,
    ${({ theme }) => theme.colors.electricViolet} 13%,
    ${({ theme }) => theme.colors.cornflowerBlue} 88%
  );
  left: 0;
  bottom: 0;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.sm} ${({ theme }) => theme.borderRadius.sm};
`;

export const StyledTitle = styled.div`
  justify-self: flex-start;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors.violetGray};
  opacity: 0.75;
  max-width: 100%;
  justify-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledButtonsContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(-3, -2, 2)};

  ${mq.xsLarge`
    justify-self: center;
    margin: 0;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
   `}
`;

export const StyledLinksWrapper = styled(motion.div)`
  background: ${({ theme }) => rgba(theme.colors.electricViolet, 0.85)};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`;
