import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import UploadedImg from 'utils/types/UploadedImg';

import Typography from 'components/atoms/Typography/Typography';
import { IconProps } from 'components/atoms/icons/Icon';

import { StyledIcon, StyledIconWrapper, StyledLink, StyledVideoButton } from './MediaLink.styles';

export type MediaLinkProps = {
  id?: string;
  href: string;
  media_icon: {
    key: string;
    icon: UploadedImg;
  };
  isFullSize?: boolean;
  variant?: 'sm' | 'lg';
} & IconProps;

const MediaLink: FC<MediaLinkProps> = ({
  theme,
  href,
  media_icon,
  isFullSize = false,
  variant = 'lg',
  color = theme.colors.black
}) => (
  <StyledLink href={href} isFullSize={isFullSize}>
    <StyledVideoButton>
      <StyledIconWrapper>
        <StyledIcon icon={media_icon.icon.file.url} variant={variant} color={color} />
      </StyledIconWrapper>
      <Typography variant="label" weight="800" transform="uppercase">
        {media_icon.key}
      </Typography>
    </StyledVideoButton>
  </StyledLink>
);

export default withTheme(MediaLink);
