import React, { FC } from 'react';

import useMedia from 'hooks/useMedia';
import Button from 'utils/types/Button';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { FrontendNews, FrontendNewsProps } from 'components/FrontendNews/FrontendNews';

import CoreIcon from './CoreIcon/CoreIcon';

import {
  StyledCoreBackground,
  StyledColumns,
  StyledTextColumn,
  StyledIconsRow,
  StyledTextWrapper,
  StyledLeftIconsColumn,
  StyledRightIconsColumn
} from './CoreTechnologies.styles';

export type IconsProps = {
  left: string;
  right: string;
};

export type IconProps = {
  margin: string;
};

export type CoreTechProps = {
  upperText: string;
  lowerText: string;
  button: Button;
};

const renderCta = ({ upperText, lowerText, button }: CoreTechProps): JSX.Element => (
  <StyledTextColumn>
    <Typography color="secondary" variant="h3" weight="600">
      {upperText}
    </Typography>
    <StyledTextWrapper>
      <Typography color="secondary" variant="h3" renderAs="p" weight="300">
        {lowerText}
      </Typography>
    </StyledTextWrapper>
    <LinkedButton href={button.href}>{button.buttonCopy}</LinkedButton>
  </StyledTextColumn>
);

const renderDesktop = ({ upperText, lowerText, button }: CoreTechProps): JSX.Element => (
  <>
    <StyledLeftIconsColumn>
      <StyledIconsRow left="-91px">
        <CoreIcon src="/technologies/figma.svg" alt="Figma logo" margin="0 20px 0" />
        <CoreIcon src="/technologies/sketch.svg" alt="Sketch logo" margin="0 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow left="-20px">
        <CoreIcon src="/technologies/in-logo.svg" alt="in logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/zeplin.svg" alt="Zeplin logo" margin="35px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow left="29px">
        <CoreIcon src="/technologies/adobe-xd.svg" alt="Adobe XD logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/adobe-ai.svg" alt="AdobeAI logo" margin="35px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow left="-91px">
        <CoreIcon src="/technologies/photoshop.svg" alt="Photoshop logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/adobe.svg" alt="Adobe logo" margin="35px 20px 0" />
      </StyledIconsRow>
    </StyledLeftIconsColumn>
    {renderCta({ upperText, lowerText, button })}
    <StyledRightIconsColumn>
      <StyledIconsRow right="-19px">
        <CoreIcon src="/technologies/html.svg" alt="html logo" margin="0 20px 0" />
        <CoreIcon src="/technologies/javascript.svg" alt="Javascript logo" margin="0 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow right="29px">
        <CoreIcon src="/technologies/react.svg" alt="React logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/angular.svg" alt="Angular logo" margin="35px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow right="-21px">
        <CoreIcon src="/technologies/python.svg" alt="Python logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/node.svg" alt="node js logo" margin="35px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow right="-89px">
        <CoreIcon src="/technologies/android.svg" alt="Android logo" margin="35px 20px 0" />
        <CoreIcon src="/technologies/iOS.svg" alt="Apple logo" margin="35px 20px 0" />
      </StyledIconsRow>
    </StyledRightIconsColumn>
  </>
);

const renderMobile = ({ upperText, lowerText, button }: CoreTechProps): JSX.Element => (
  <>
    <StyledLeftIconsColumn>
      <StyledIconsRow>
        <CoreIcon src="/technologies/figma.svg" alt="Figma logo" margin="80px 20px 0" />
        <CoreIcon src="/technologies/sketch.svg" alt="Sketch logo" margin="30px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow left="10%">
        <CoreIcon src="/technologies/in-logo.svg" alt="in logo" margin="30px 20px 0" />
        <CoreIcon src="/technologies/html.svg" alt="Html logo" margin="30px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow left="20%">
        <CoreIcon src="/technologies/python.svg" alt="Python logo" margin="30px 20px 0" />
        <CoreIcon src="/technologies/react.svg" alt="React logo" margin="30px 20px 0" />
      </StyledIconsRow>
      <StyledIconsRow>
        <CoreIcon src="/technologies/javascript.svg" alt="Javascript logo" margin="30px 20px 0" />
        <CoreIcon src="/technologies/angular.svg" alt="Angular logo" margin="30px 20px 0" />
      </StyledIconsRow>
    </StyledLeftIconsColumn>
    {renderCta({ upperText, lowerText, button })}
  </>
);

export const CoreTechnologies: FC<CoreTechProps & FrontendNewsProps> = ({
  upperText,
  lowerText,
  button,
  header,
  description,
  frontendButton,
  youtubeLink,
  videos,
  media_icon
}) => {
  const { isMobile } = useMedia();
  const ctaProps = { upperText, lowerText, button };

  return (
    <StyledCoreBackground>
      <Container>
        <StyledColumns>{isMobile ? renderMobile(ctaProps) : renderDesktop(ctaProps)}</StyledColumns>
        <FrontendNews
          header={header}
          description={description}
          frontendButton={frontendButton}
          youtubeLink={youtubeLink}
          videos={videos}
          media_icon={media_icon}
        />
      </Container>
    </StyledCoreBackground>
  );
};
