import React, { FC } from 'react';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { VideoTile, VideoTileProps } from 'components/organisms/VideoSection/VideoTile/VideoTile';
import IconLink from 'components/atoms/IconLink/IconLink';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import Button from 'utils/types/Button';
import UploadedImg from 'utils/types/UploadedImg';

import {
  StyledContainer,
  StyledTextColumn,
  StyledWrapper,
  StyledTitleWrapper,
  StyledUpperRow,
  StyledDescriptionWrapper,
  StyledLowerRow,
  StyledMainVideoWrapper,
  StyledSecondaryVideoContainer,
  StyledYoutubeWrapper
} from './FrontendNews.styles';

export type FrontendNewsProps = {
  header: string;
  description: string;
  frontendButton: Button;
  youtubeLink: Button;
  videos: Array<VideoTileProps>;
  media_icon: {
    icon: UploadedImg;
  };
};

const renderCta = ({ header, description, frontendButton, youtubeLink, media_icon }): JSX.Element => (
  <StyledTextColumn>
    <StyledTitleWrapper>
      <Typography variant="h1" renderAs="h2" weight="700">
        {header}
      </Typography>
    </StyledTitleWrapper>
    <StyledDescriptionWrapper>
      <Typography variant="body1" weight="200">
        {description}
      </Typography>
    </StyledDescriptionWrapper>
    <LinkedButton href={frontendButton.href} variant="primary" width="262px">
      {frontendButton.buttonCopy}
    </LinkedButton>
    <StyledYoutubeWrapper>
      <IconLink button={youtubeLink} image={media_icon.icon} />
    </StyledYoutubeWrapper>
  </StyledTextColumn>
);

const renderDesktop = ({
  header,
  description,
  frontendButton,
  youtubeLink,
  videos,
  media_icon
}: FrontendNewsProps): JSX.Element => {
  const firstVideo = videos[0];
  const restVideos = videos.slice(1);

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledUpperRow>
          {renderCta({ header, description, frontendButton, youtubeLink, media_icon })}
          <StyledMainVideoWrapper>
            <VideoTile
              thumbnail={firstVideo.thumbnail}
              title={firstVideo.title}
              slug={firstVideo.slug}
              description={firstVideo.description}
              videoId={firstVideo.videoId}
              isBig
            />
          </StyledMainVideoWrapper>
        </StyledUpperRow>
        {restVideos.length > 0 && (
          <StyledLowerRow>
            {restVideos.map(({ id, videoId, slug, thumbnail }) => (
              <StyledSecondaryVideoContainer key={id}>
                <VideoTile thumbnail={thumbnail} slug={slug} videoId={videoId} />
              </StyledSecondaryVideoContainer>
            ))}
          </StyledLowerRow>
        )}
      </StyledWrapper>
    </StyledContainer>
  );
};

const renderMobile = ({ header, description, frontendButton, youtubeLink, videos, media_icon }: FrontendNewsProps) => (
  <StyledWrapper>
    <div>{renderCta({ header, description, frontendButton, youtubeLink, media_icon })}</div>
    <VideoTile thumbnail={videos[0].thumbnail} slug={videos[0].slug} videoId={videos[0].videoId} />
  </StyledWrapper>
);

export const FrontendNews: FC<FrontendNewsProps> = (props) => {
  const { isMobile } = useMedia();

  return isMobile ? renderMobile(props) : renderDesktop(props);
};
