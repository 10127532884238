import React, { FC, useState } from 'react';
import Image from '@next/image';
import { useTheme } from 'styled-components';

import { useMinWidthQuery } from 'hooks/useMedia';
import fromCMS from 'utils/fromCMS';
import formatDate from 'utils/formatDate';
import config from 'config';
import UploadedImg from 'utils/types/UploadedImg';
import { fadeInTopIcons, fadeInWithHiddenChildren } from 'theme/animations';

import { AppLink } from 'components/AppLink/AppLink';
import Typography from 'components/atoms/Typography/Typography';
import MediaLink, { MediaLinkProps } from 'components/organisms/VideoSection/MediaLink/MediaLink';

import {
  StyledButtonsContainer,
  StyledContainer,
  StyledDate,
  StyledDescription,
  StyledLink,
  StyledLinksWrapper,
  StyledThumbnailWrapper,
  StyledTitle,
  StyledTitleWrapper
} from './VideoTile.styles';

export type VideoTileProps = {
  id?: number;
  date?: string;
  thumbnail: UploadedImg;
  title?: string;
  slug: string;
  description?: string;
  videoId?: string;
  isBig?: boolean;
};

export const VideoTile: FC<VideoTileProps> = ({
  date,
  thumbnail,
  title,
  description,
  slug,
  videoId,
  isBig = false
}) => {
  const theme = useTheme();
  const isDesktop = useMinWidthQuery('xsLarge');
  const { ROUTES } = config;
  const [isHover, setIsHover] = useState(false);
  const autoReadLink: MediaLinkProps = {
    id: '0',
    href: `${ROUTES.NEWS.href}/${slug}`,
    media_icon: {
      key: 'CHECK MORE',
      icon: { file: { url: '/icons/read.svg' }, alt: 'Read icon' }
    }
  };

  return (
    <StyledContainer
      onMouseEnter={
        isDesktop
          ? () => {
              setIsHover(true);
            }
          : undefined
      }
      onMouseLeave={
        isDesktop
          ? () => {
              setIsHover(false);
            }
          : undefined
      }
    >
      {date && (
        <StyledDate>
          <Typography variant="caption" weight="300">
            {formatDate(new Date(date))}
          </Typography>
        </StyledDate>
      )}
      <StyledThumbnailWrapper>
        <StyledLink href={autoReadLink.href}>
          <Image
            src={
              thumbnail.file ? fromCMS(thumbnail.file.url) : `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            }
            alt={thumbnail.alt ? thumbnail.alt : title}
            layout="fill"
            objectFit="cover"
            hasFade={false}
          />
        </StyledLink>
        {isDesktop && (
          <StyledLinksWrapper
            animate={isHover ? 'visible' : 'hidden'}
            initial="hidden"
            variants={fadeInWithHiddenChildren}
          >
            <StyledButtonsContainer variants={fadeInTopIcons}>
              <MediaLink
                id={autoReadLink.id}
                media_icon={autoReadLink.media_icon}
                href={autoReadLink.href}
                isFullSize
                variant="sm"
                color={theme.colors.white}
              />
            </StyledButtonsContainer>
          </StyledLinksWrapper>
        )}
      </StyledThumbnailWrapper>
      {isBig ? (
        <StyledTitleWrapper>
          <AppLink href={autoReadLink.href}>
            <StyledTitle>
              <Typography variant="body1" weight="700" ellipsisLines={1} title={title}>
                {title}
              </Typography>
            </StyledTitle>
          </AppLink>
          <StyledDescription>
            <Typography variant="caption" ellipsisLines={1} title={description}>
              {description}
            </Typography>
          </StyledDescription>
        </StyledTitleWrapper>
      ) : (
        <>
          {title && (
            <AppLink href={autoReadLink.href}>
              <StyledTitle>
                <Typography variant="h4" ellipsisLines={1} title={title}>
                  {title}
                </Typography>
              </StyledTitle>
            </AppLink>
          )}
          {description && (
            <StyledDescription>
              <Typography variant="caption" ellipsisLines={2} title={description}>
                {description}
              </Typography>
            </StyledDescription>
          )}
        </>
      )}
    </StyledContainer>
  );
};
