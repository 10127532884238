import styled, { css } from 'styled-components';

import { AppLink } from 'components/AppLink/AppLink';

import { MediaLinkProps } from './MediaLink';

export const StyledLink = styled(AppLink)<{ isFullSize?: MediaLinkProps['isFullSize'] }>`
  display: flex;
  align-items: center;
  transition: 200ms ease-in-out;

  &:hover {
    opacity: 0.65;
  }

  ${({ isFullSize }) =>
    isFullSize &&
    css`
      width: 100%;
      height: 100%;
      justify-content: center;
      transition-delay: 200ms;
    `}
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledVideoButton = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled.div<{
  icon: string;
  variant: MediaLinkProps['variant'];
  color: MediaLinkProps['color'];
}>`
  width: 18px;
  height: 18px;

  ${({ icon }) =>
    icon &&
    css`
      mask: url(${icon}) no-repeat center / contain;
    `};

  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `};

  ${({ variant }) =>
    variant === 'lg' &&
    css`
      width: 24px;
      height: 24px;
    `};
`;
